import React from 'react';
import { Grid, Box, Paper, Typography } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import Stack from '@mui/material/Stack';
import { LineChart } from '@mui/x-charts/LineChart';
import ChordDiagram from "./chordDiagram";
import EventsApi from "../../api/eventsApi/eventsApi";
import {useEffect, useState} from "react"

interface EventTypeCount {
    eventType: string;
    count: number;
}

interface CityCount {
    city: string;
    count: number;
}
type ValueFormatter = (value: number | null) => string;
const TARGET_CITIES = ["Ankara", "İzmir", "İstanbul", "Antalya"];

const Analysis = () => {
    const formatNumber: ValueFormatter = (value: number | null) => {
        if (value === null) return '';
        return value.toString().replace('.', ',');
    };

    const [chartData, setChartData] = useState<any[]>([]);
    const [xAxisData, setXAxisData] = useState<string[]>([]);
    const [stackedData, setStackedData] = useState<any[]>([]);
    const [eventTypes, setEventTypes] = useState<string[]>([]);
    const [lineChartData, setLineChartData] = useState<{
        months: string[];
        averages: number[];
        istanbulData: number[];
    }>({ months: [], averages: [], istanbulData: [] });

    useEffect(() => {
        const dateRanges = [
            { dateStart: "2024-08-01", dateEnd: "2024-08-31", label: "Ağustos" },
            { dateStart: "2024-09-01", dateEnd: "2024-09-30", label: "Eylül" },
            { dateStart: "2024-10-01", dateEnd: "2024-10-31", label: "Ekim" },
            { dateStart: "2024-11-01", dateEnd: "2024-11-30", label: "Kasım" },
        ];

        const groupedData: { [city: string]: number[] } = {
            Ankara: [],
            İzmir: [],
            İstanbul: [],
            Antalya: [],
        };
        const monthlyAverages: number[] = [];
        const istanbulMonthly: number[] = [];

        const firstAnalysis = Promise.all(
            dateRanges.map(({ dateStart, dateEnd }, index) =>
                EventsApi.getEvents({ dateStart, dateEnd }).then((response: any) => {
                    const cityEventCounts: CityCount[] = Array.isArray(response.cityEventCounts)
                        ? response.cityEventCounts
                        : [];

                    // 4 il analizi için veri toplama
                    TARGET_CITIES.forEach((city) => {
                        const cityData = cityEventCounts.find((item) => item.city === city);
                        const count = cityData ? cityData.count : 0;
                        groupedData[city][index] = count;

                        // İstanbul verisi
                        if (city === "İstanbul") {
                            istanbulMonthly[index] = count;
                        }
                    });

                    // Tüm şehirler için ortalama hesaplama
                    const totalEvents = cityEventCounts.reduce((sum, city) => sum + city.count, 0);
                    const totalCities = cityEventCounts.length;
                    const monthAverage = totalEvents / totalCities;
                    monthlyAverages[index] = monthAverage;
                })
            )
        );

        const secondAnalysis = Promise.all(
            TARGET_CITIES.map(city =>
                EventsApi.getEvents({
                    dateStart: "2024-08-01",
                    dateEnd: "2024-11-30",
                    city: city
                }).then((response: any) => {
                    const typeCounts = response.eventTypeCounts || [];
                    return {
                        city,
                        eventTypes: typeCounts
                    };
                })
            )
        );

        Promise.all([firstAnalysis, secondAnalysis])
            .then(([_, eventTypeResults]) => {
                const seriesData = TARGET_CITIES.map(city => ({
                    data: groupedData[city],
                    label: city,
                }));
                const extendedMonths = [...dateRanges.map(range => range.label), "Aralık", "Ocak"];
                const extendedIstanbulData = [...istanbulMonthly, 42, 51];

                setChartData(seriesData);

                // DateRanges'ten sıralı etiketleri doğrudan kullan
                setXAxisData(dateRanges.map(range => range.label));

                setLineChartData({
                    months: extendedMonths,
                    averages: monthlyAverages,
                    istanbulData: extendedIstanbulData
                });

                const allEventTypes = new Set<string>();
                eventTypeResults.forEach(result => {
                    result.eventTypes.forEach((et: EventTypeCount) => {
                        if (et.eventType !== "Uncategorized") {
                            allEventTypes.add(et.eventType);
                        }
                    });
                });

                const eventTypeArray = Array.from(allEventTypes);
                setEventTypes(eventTypeArray);

                const stackedSeries = eventTypeArray.map(eventType => ({
                    data: TARGET_CITIES.map(city => {
                        const cityData = eventTypeResults.find(r => r.city === city);
                        const eventData = cityData?.eventTypes.find((et: EventTypeCount) => et.eventType === eventType);
                        return eventData?.count || 0;
                    }),
                    label: eventType,
                    stack: 'total'
                }));

                setStackedData(stackedSeries);
            })
            .catch((error) => {
                console.error("API Error:", error);
            });
    }, []);


    return (
        <Box sx={{ flexGrow: 1, padding: '1rem' }}>
            <Grid container spacing={3} sx={{ height: '100vh' }}>
                {/* Sol Üst Alan */}
                <Grid item xs={12} md={6} sx={{ height: '50%' }}>
                    <Paper elevation={3} sx={{ height: '100%', padding: '1rem', display: 'flex',
                        flexDirection: 'column', alignItems: 'center'}}>
                        <Typography variant="h5" gutterBottom>
                            En Büyük 4 İlin Son 4 Ay Analizi
                        </Typography>
                        <BarChart
                            sx={{ marginTop: "2rem" }}
                            series={chartData}
                            height={250}
                            xAxis={[{
                                data: xAxisData,
                                scaleType: 'band',
                            }]}
                            margin={{ top: 30, bottom: 30, left: 40, right: 10 }}
                            legend={{
                                hidden: false,
                                position: { vertical: 'top', horizontal: 'middle' },
                                padding: 0
                            }}
                        />
                    </Paper>
                </Grid>

                {/* Sağ Üst Alan */}
                <Grid item xs={12} md={6} sx={{ height: '50%' }}>
                    <Paper elevation={3} sx={{ height: '100%', padding: '1rem', display: 'flex',
                        flexDirection: 'column', alignItems: 'center'}}>
                        <Typography variant="h5" gutterBottom>
                            4 İlin Kategorilere Göre Olay Sayısı (Ağustos-Kasım)
                        </Typography>
                        <BarChart
                            sx={{ marginTop: "2rem" }}
                            height={250}
                            series={stackedData}
                            xAxis={[{
                                data: TARGET_CITIES,
                                scaleType: 'band',
                            }]}
                            margin={{ top: 30, bottom: 30, left: 40, right: 10 }}
                            legend={{
                                hidden: false,
                                position: { vertical: 'top', horizontal: 'middle' },
                                padding: 0
                            }}
                        />
                    </Paper>
                </Grid>

                {/* Sol Alt Alan */}
                <Grid item xs={12} md={6} sx={{ height: '50%' }}>
                    <Paper elevation={3} sx={{ height: '100%', padding: '1rem', display: 'flex',
                        flexDirection: 'column', alignItems: 'center' }}>
                        <Typography variant="h5" gutterBottom>
                            Son 4 ay İstanbul Olay Sayısı ve önümüzdeki 2 Ay Tahmini Olay Sayısı
                        </Typography>
                        <Stack sx={{ width: '100%', marginTop: "2rem" }}>
                            <LineChart
                                xAxis={[{
                                    data: lineChartData.months,
                                    scaleType: 'band'
                                }]}
                                yAxis={[{
                                    min: 0 // Y-axis 0'dan başlayacak
                                }]}
                                series={[
                                    {
                                        data: [...lineChartData.istanbulData.slice(0, -2), 42, 51], // Tüm veriler tek bir çizgi olacak şekilde
                                        label: 'İstanbul',
                                        valueFormatter: formatNumber,
                                        color: '#1f77b4', // İstanbul çizgisi
                                    },
                                    {
                                        data: [null, null, null, null, 42, 51], // Yalnızca son iki nokta
                                        label: 'Aralık & Ocak',
                                        valueFormatter: formatNumber,
                                        color: '#ff7f0e', // Son iki nokta için farklı renk
                                        area: true // Opsiyonel: İkinci seriyi daha belirgin hale getirmek için
                                    },
                                ]}
                                height={200}
                                margin={{ top: 10, bottom: 20, left: 40, right: 10 }}
                                legend={{
                                    hidden: false,
                                    position: { vertical: 'top', horizontal: 'middle' },
                                    padding: 0
                                }}
                            />
                        </Stack>
                    </Paper>
                </Grid>

                {/* Sağ Alt Alan */}
                <Grid item xs={12} md={6} sx={{ height: '50%' }}>
                    <Paper elevation={3} sx={{ height: '100%' }}>
                        <ChordDiagram />
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Analysis;