import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {DateRangePicker} from 'react-date-range';
import TextField from "@mui/material/TextField";
import {useState} from "react";
import {
	endOfWeek,
	format,
	isSameDay,
	startOfMonth,
	startOfWeek,
	subDays,
	subMonths,
	subWeeks,
	subYears
} from "date-fns";
import {locale} from "../../constants/consts";
import {Popover} from "@mui/material";

export interface IRange {
	startDate: Date;
	endDate: Date;
}

interface DateRangePickerProps {
	label?: string | undefined | null;
	dateRange: IRange;
	setDateRange: any;
}

const CustomDateRangePicker = (props: DateRangePickerProps) => {
	const {dateRange, setDateRange, label} = props;
	const [open, setOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState<any>(null);

	const handleSelect = (item: any) => {
		setDateRange({startDate: item.selection.startDate, endDate: item.selection.endDate})
	};

	const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
		setAnchorEl(event.currentTarget);
		setOpen(true);
	};
	return <>
		<TextField
			fullWidth
			size={"small"}
			label={label}
			onClick={handleClick}
			value={`${format(dateRange.startDate, 'dd-MM-yyyy')} - ${format(dateRange.endDate, 'dd-MM-yyyy')}`}
		/>
		<Popover
			anchorEl={anchorEl}
			open={open}
			onClose={() => {
				setAnchorEl(null);
				setOpen(false);
			}}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
		>
			<DateRangePicker
				locale={locale}
				onChange={handleSelect}
				ranges={[{...dateRange, key: 'selection'}]}
				direction="vertical"
				maxDate={new Date()}
				staticRanges={[
					{
						label: 'Bugün',
						range: () => ({
							startDate: new Date(),
							endDate: new Date()
						}),
						isSelected(range) {
							const definedRange = this.range();
							return (
								!!range.startDate && !!definedRange.startDate && !!range.endDate && !!definedRange.endDate &&
								isSameDay(range.startDate, definedRange.startDate) &&
								isSameDay(range.endDate, definedRange.endDate)
							);
						}
					},
					{
						label: 'Geçen Hafta',
						range: () => ({
							startDate: subWeeks(startOfWeek(new Date()), 1),
							endDate: subWeeks(endOfWeek(new Date()), 1)
						}),
						isSelected(range) {
							const definedRange = this.range();
							return (
								!!range.startDate && !!definedRange.startDate && !!range.endDate && !!definedRange.endDate &&
								isSameDay(range.startDate, definedRange.startDate) &&
								isSameDay(range.endDate, definedRange.endDate)
							);
						}
					},
					{
						label: 'Geçen Ay',
						range: () => ({
							startDate: subMonths(startOfMonth(new Date()), 1),
							endDate: subDays(startOfMonth(new Date()), 1)
						}),
						isSelected(range) {
							const definedRange = this.range();
							return (
								!!range.startDate && !!definedRange.startDate && !!range.endDate && !!definedRange.endDate &&
								isSameDay(range.startDate, definedRange.startDate) &&
								isSameDay(range.endDate, definedRange.endDate)
							);
						}
					},
					{
						label: 'Son 1 Ay',
						range: () => ({
							startDate: subMonths(new Date(), 1),
							endDate: new Date(),
						}),
						isSelected(range) {
							const definedRange = this.range();
							return (
								!!range.startDate && !!definedRange.startDate && !!range.endDate && !!definedRange.endDate &&
								isSameDay(range.startDate, definedRange.startDate) &&
								isSameDay(range.endDate, definedRange.endDate)
							);
						}
					},
					{
						label: 'Son 1 Yıl',
						range: () => ({
							startDate: subYears(new Date(), 1),
							endDate: new Date()
						}),
						isSelected(range) {
							const definedRange = this.range();
							return (
								!!range.startDate && !!definedRange.startDate && !!range.endDate && !!definedRange.endDate &&
								isSameDay(range.startDate, definedRange.startDate) &&
								isSameDay(range.endDate, definedRange.endDate)
							);
						}
					},
					{
						label: 'Son 3 Yıl',
						range: () => ({
							startDate: subYears(new Date(), 3),
							endDate: new Date()
						}),
						isSelected(range) {
							const definedRange = this.range();
							return (
								!!range.startDate && !!definedRange.startDate && !!range.endDate && !!definedRange.endDate &&
								isSameDay(range.startDate, definedRange.startDate) &&
								isSameDay(range.endDate, definedRange.endDate)
							);
						}
					}
				]}
			/>
		</Popover>
	</>
}
export default CustomDateRangePicker