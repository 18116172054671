import axios, {AxiosResponse, InternalAxiosRequestConfig} from 'axios';
import store from "../redux/store";
import {startLoading, stopLoading} from "../redux/reducers/loadingReducer";
import Links from "../constants/Links";

const baseUrl = Links.BASE_URL_WITHOUT_TENANT;
let accessTokenId = -1;
const ApiBase = {
	login(credentials: any) {
		return ApiBase.postPromise('/api/Auth/Login', credentials).then((resp: any) => {
			return resp;
		});
	},

	putAccessTokenInfo(accessToken: any) {
		accessTokenId !== -1 && axios.interceptors.request.eject(accessTokenId);
		accessTokenId = axios.interceptors.request.use(
			(config: any) => {
				config.headers['Authorization'] = accessToken ? `Bearer ${accessToken}` : undefined;
				return config;
			},
			function (error: Error) {
				return Promise.reject(error);
			},
		);
	},


	getPromise(endpoint: string, params?: object | undefined) {
		return new Promise((resolve, reject) => {
			axios
				.get(baseUrl + endpoint, {params})
				.then((response: AxiosResponse) => resolve(response.data))
				.catch((error: Error) => reject(error));
		});
	},

	getPromiseWithParams(endpoint: string, params: any) {
		return new Promise((resolve, reject) => {
			axios({
				method: 'GET',
				params: params,
				url: baseUrl + endpoint,
			})
				.then((response: AxiosResponse) => resolve(response.data))
				.catch((error: Error) => reject(error));
		});
	},

	postPromise(endpoint: string, data: any) {
		return new Promise((resolve, reject) => {
			axios({
				method: 'POST',
				data: data,
				url: baseUrl + endpoint,
			})
				.then((response: AxiosResponse) => resolve(response.data))
				.catch((error: Error) => reject(error));
		});
	},

	postPromiseFormData(endpoint: string, data: FormData) {
		return new Promise((resolve, reject) => {
			axios({
				method: 'POST',
				data: data,
				url: baseUrl + endpoint,
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})
				.then((response: AxiosResponse) => resolve(response.data))
				.catch((error: Error) => reject(error));
		});
	},

	postPromiseWithoutData(endpoint: string) {
		return new Promise((resolve, reject) => {
			axios({
				method: 'POST',
				url: baseUrl + endpoint,
			})
				.then((response: AxiosResponse) => resolve(response.data))
				.catch((error: Error) => reject(error));
		});
	},


	deletePromise(endpoint: string, data: any) {
		return new Promise((resolve, reject) => {
			axios({
				method: 'DELETE',
				data: data,
				url: baseUrl + endpoint,
			})
				.then((response: AxiosResponse) => resolve(response.data))
				.catch((error: Error) => reject(error));
		});
	},

	putPromise(endpoint: string, data: any) {
		return new Promise((resolve, reject) => {
			axios({
				method: 'PUT',
				data: data,
				url: baseUrl + endpoint,
			})
				.then((response: AxiosResponse) => resolve(response.data))
				.catch((error: Error) => reject(error));
		});
	},
};

axios.interceptors.request.use((config: InternalAxiosRequestConfig) => {
	(store.dispatch)(startLoading());
	return config;
});

axios.interceptors.response.use(
	(response: AxiosResponse) => {
		(store.dispatch)(stopLoading());
		return response;
	},
	(error: Error) => {
		(store.dispatch)(stopLoading());
		return Promise.reject(error);
	},
);

export default ApiBase;
