import {
	Dialog, DialogContent, DialogTitle, Paper, Typography, Button,
	DialogActions, Radio, RadioGroup, FormControlLabel, FormControl,
	TextField, Box
} from "@mui/material";
import {Circle, GeoJSON, MapContainer, TileLayer, useMap} from "react-leaflet";
import React, {useEffect} from "react";
import il_sinirlari from "../../../assets/geojson/tr-cities-utf8.json";
import * as L from "leaflet";
import {LatLngExpression} from "leaflet";
import {addFeatureEvents, getFeatureStyle} from "../../../helpers/mapStyles";
import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';
import {useSelector} from "../../../redux/store";
import eventsApi from "../../../api/eventsApi/eventsApi";
import Markdown from "markdown-to-jsx";
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {AccordionSummaryProps, accordionSummaryClasses} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import {formData} from "../../../assets/formData";

interface IMapProps {
	events: any,
	filters: any,
	setFilters: any,
	pins: any,
	setPins: any
}

interface FormAnswers {
	[key: string]: {
		answer: string;
		description: string;
	};
}

interface EventCount {
	city: string;
	count: number;
}

interface DistrictEventCount {
	district: string;
	count: number;
}

interface PinDialogProps {
	open: boolean;
	onClose: () => void;
	pin: any;
}



const PinDialog = ({ open, onClose, pin }: PinDialogProps) => {
	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>{pin?.name || 'Pin Details'}</DialogTitle>
			<DialogContent>
				<div className="space-y-4">
					<div>
						<strong>Sector:</strong> {pin?.sector}
					</div>
					<div>
						<strong>Radius:</strong> {pin?.radiusInMeters?.toFixed(2)} meters
					</div>
					<div>
						<strong>Event Count:</strong> {pin?.events?.length ?? 0}
					</div>
					<div>
						<strong>Report:</strong> {pin?.reportDescription ?? ""}
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
};

const Map = (props: IMapProps) => {
	const {events, filters, setFilters, pins, setPins} = props;

	const center: LatLngExpression = [38.9637, 35.2433];
	return (
		<Paper elevation={3} sx={{width: '100%', height: '70vh', overflow: 'hidden'}}>

			<MapContainer
				center={center}
				zoom={6}
				style={{height: '100%', width: '100%'}}
			>
				<MapComponents events={events} filters={filters} setFilters={setFilters} pins={pins} setPins={setPins}/>
			</MapContainer>
		</Paper>
	)
}
const MapComponents = (props: IMapProps) => {
	const {events, filters, setFilters, pins, setPins} = props;
	// const [pinDef, setPinDef] = React.useState<any>({open: false, polygon: false});
	const map = useMap();

	const userId = useSelector(state => state.auth.id);

	const {districtBounds: ilce_sinirlari} = useSelector(state => state.app)
	// const drawnItems = L.featureGroup().addTo(map);

	useEffect(() => {
		const drawControl = new L.Control.Draw({
			// edit: {
			// 	featureGroup: drawnItems,
			// },
			draw: {
				polyline: false,
				rectangle: false,
				marker: false,
				circlemarker: false,
				polygon: {
					allowIntersection: false,
				},
				circle: {
					showRadius: true
				}
			},
		});

		drawControl.setDrawingOptions({
			polygon: {
				shapeOptions: {
					color: '#FFD700', // Altın sarısı sınır rengi
				},
			},
		});
		if (filters.city) {
			map.addControl(drawControl)
		}
		return () => {
			map.removeControl(drawControl);
		}
	}, [filters.city, map]);

	useEffect(() => {
		if (map) {
			const createdHandler = (e: any) => {
				const layer = e.layer;
				const formData = new FormData();
				formData.append("name", "test");
				formData.append("sector", "testSector");
				formData.append("latitude", layer._latlng.lat.toString());
				formData.append("longitude", layer._latlng.lng.toString());
				formData.append("radiusInMeters", layer._mRadius.toString());
				formData.append("userId", userId?.toString() ?? "");
				eventsApi.createNewPin(formData).then((res: any) => setPins([...pins, res]))

				// drawnItems.addLayer(layer);
			};

			map.on(L.Draw.Event.CREATED, createdHandler);

			return () => {
				map.off(L.Draw.Event.CREATED, createdHandler);
			};
		}
	}, [map]);


	const calculateTValue = (count: number): number => {
		return Math.log(count + 1) * 3; // Basit bir risk hesaplaması
	}

	const interpretTValue = (t: number): string => {
		if (t < 3) return 'Düşük';
		if (t >= 3 && t <= 6) return 'Orta';
		if (t >= 6 && t <= 12) return 'Yüksek';
		return 'Çok Yüksek';
	}

	var ilSinirlari: any = il_sinirlari;

	const eventCounts: any = {}
	if (!filters.city && !!events.cityEventCounts) {
		events.cityEventCounts.forEach((city: EventCount) => {
				eventCounts[city.city] = {
					city: city.city,
					count: city.count,
					t: calculateTValue(city.count),
					tInterpretation: interpretTValue(calculateTValue(city.count))
				}
			}
		);
	} else if (filters.city && !!events.districtEventCounts) {
		events.districtEventCounts.forEach((district: DistrictEventCount) => {
				eventCounts[district.district] = {
					city: district.district,
					count: district.count,
					t: calculateTValue(district.count),
					tInterpretation: interpretTValue(calculateTValue(district.count))
				}
			}
		);
	}

	const CityBounds = () => {
		map.setView(center, 6)

		return ilSinirlari.features.map((feature: any) => {
			const city = feature.properties.name;
			const eventData = eventCounts[city];

			return (
				<GeoJSON
					key={feature.id}
					data={feature}
					style={getFeatureStyle(eventData, filters.eventTypeId)}
					onEachFeature={(feature: any, layer: any) => {
						layer.on(
							{
								click: () => {
									setFilters({...filters, city});
								}
							}
						);
						addFeatureEvents(layer)
					}}
				/>
			);
		})
	}

	const DistrictBounds = () => {
		const ilFeature = ilSinirlari.features.find((q: any) => q.properties.name === filters.city);
		if (ilFeature) {
			const bounds = L.latLngBounds(ilFeature.geometry.coordinates)
			map.fitBounds(new L.LatLngBounds(new L.LatLng(bounds.getSouthWest().lng, bounds.getSouthWest().lat), new L.LatLng(bounds.getNorthEast().lng, bounds.getNorthEast().lat)));
		}

		return ilce_sinirlari?.features.filter((q: any) => q.properties.IL_ADI === filters.city).map((feature: any) => {
			const eventData = eventCounts[feature.properties.ILCE_ADI];
			return (
				<GeoJSON
					pane="tilePane"
					key={feature.id}
					data={feature}
					style={getFeatureStyle(eventData, filters.eventTypeId)}
					onEachFeature={(feature, layer) => {
						addFeatureEvents(layer)
					}}
				/>
			);
		})
	}

	const MarkdownOptions = {
		overrides: {
			h4: {
				component: Typography,
				props: {
					variant: 'h6',
					color: 'primary',
					gutterBottom: true,
					sx: { mt: 2 }
				},
			},
			h3: {
				component: Typography,
				props: {
					variant: 'h5',
					color: 'primary',
					gutterBottom: true,
					sx: { mt: 3 }
				},
			},
			p: {
				component: Typography,
				props: {
					paragraph: true,
					sx: { mb: 2 }
				},
			},
			ul: {
				props: {
					style: {
						marginBottom: '1rem',
						paddingLeft: '1.5rem'
					}
				},
			},
			li: {
				component: Typography,
				props: {
					component: 'li',
					sx: { mb: 1 }
				},
			}
		},
	};

	const Accordion = styled((props: AccordionProps) => (
		<MuiAccordion disableGutters elevation={0} square {...props} />
	))(({ theme }) => ({
		border: `1px solid ${theme.palette.divider}`,
		'&:not(:last-child)': {
			borderBottom: 0,
		},
		'&::before': {
			display: 'none',
		},
	}));

	const AccordionSummary = styled((props: AccordionSummaryProps) => (
		<MuiAccordionSummary
			expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
			{...props}
		/>
	))(({ theme }) => ({
		backgroundColor: 'rgba(0, 0, 0, .03)',
		flexDirection: 'row-reverse',
		[`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]: {
			transform: 'rotate(90deg)',
		},
		[`& .${accordionSummaryClasses.content}`]: {
			marginLeft: theme.spacing(1),
		},
	}));

	const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
		padding: theme.spacing(2),
		borderTop: '1px solid rgba(0, 0, 0, .125)',
	}));


	const PinBounds = () => {
		const [selectedPin, setSelectedPin] = React.useState<any>(null);
		const [dialogOpen, setDialogOpen] = React.useState(false);
		const [formDialogOpen, setFormDialogOpen] = React.useState(false);
		const [expanded, setExpanded] = React.useState<string>("");
		const [answers, setAnswers] = React.useState<FormAnswers>({});

		const handlePinClick = (pin: any) => {
			setSelectedPin(pin);
			setDialogOpen(true);
		};

		const handleDialogClose = () => {
			setDialogOpen(false);
			setSelectedPin(null);
		};

		const handleAnswerChange = (questionId: string, value: string) => {
			setAnswers(prev => ({
				...prev,
				[questionId]: {
					...prev[questionId],
					answer: value
				}
			}));
		};

		const handleDescriptionChange = (questionId: string, value: string) => {
			setAnswers(prev => ({
				...prev,
				[questionId]: {
					...prev[questionId],
					description: value
				}
			}));
		};

		const handleChange = (panel: string) => (_event: React.SyntheticEvent, newExpanded: boolean) => {
			setExpanded(newExpanded ? panel : '');
		};

		return (
			<React.Fragment>
				{DistrictBounds()}
				{pins?.map((pin: any) => (
					<Circle
						key={pin.id}
						center={[pin.latitude, pin.longitude]}
						radius={pin.radiusInMeters}
						pathOptions={{color: 'blue', fillColor: 'lightblue', fillOpacity: 0.5}}
						eventHandlers={{
							click: () => handlePinClick(pin)
						}}
					/>
				))}
				<Dialog
					open={dialogOpen}
					onClose={handleDialogClose}
					maxWidth="md"
					fullWidth
					scroll="paper"
				>
					<DialogTitle>{selectedPin?.name || 'Pin Detayları'}</DialogTitle>
					<DialogContent dividers>
						<div className="space-y-4">
							<div>
								<Typography variant="subtitle1" gutterBottom>
									<strong>Sektör:</strong> {selectedPin?.sector}
								</Typography>
							</div>
							<div>
								<Typography variant="subtitle1" gutterBottom>
									<strong>Yarıçap:</strong> {selectedPin?.radiusInMeters?.toFixed(2)} metre
								</Typography>
							</div>
							<div>
								<Typography variant="subtitle1" gutterBottom>
									<strong>Olay Sayısı:</strong> {selectedPin?.events?.length ?? 0}
								</Typography>
							</div>
							{selectedPin?.reportDescription && (
								<Paper
									elevation={0}
									sx={{
										p: 2,
										mt: 2,
										backgroundColor: '#f5f5f5',
										maxHeight: '60vh',
										overflow: 'auto'
									}}
								>
									<Markdown options={MarkdownOptions}>
										{selectedPin.reportDescription}
									</Markdown>
								</Paper>
							)}

							{/* Kontrol Formu Butonu */}
							<Button
								sx={{
									display: 'flex',
									justifyContent: 'center',
									width: '50%',
									height: '40px',
									backgroundColor: '#3D8195',
									color: '#fff',
									borderRadius: '8px',
									marginTop: '1rem'
								}}
								onClick={() => setFormDialogOpen(true)}
							>
								Kontrol Formu
							</Button>
						</div>
					</DialogContent>
				</Dialog>

				{/* Kontrol Form Dialog'u */}
				<Dialog
					open={formDialogOpen}
					onClose={() => setFormDialogOpen(false)}
					maxWidth="md"
					fullWidth
				>
					<DialogContent>
						<DialogTitle>RİSK ANALİZ FORMU</DialogTitle>
						<div>
							<Typography sx={{fontSize:"12px", paddingLeft:"24px", marginBottom:"12px"}}>
								Risk Analiz Formu {formData.categories.length} bölümden oluşmaktadır.
							</Typography>

							{formData.categories.map((category) => (
								<Accordion
									key={category.categoryId}
									expanded={expanded === category.categoryId}
									onChange={handleChange(category.categoryId)}
								>
									<AccordionSummary
										aria-controls={`${category.categoryId}-content`}
										id={`${category.categoryId}-header`}
									>
										<Typography>{category.categoryName}</Typography>
									</AccordionSummary>
									<AccordionDetails>
										{category.questions.map((question, index) => (
											<Box key={question.questionId} sx={{ mb: 3 }}>
												<Typography
													sx={{
														fontSize: '14px',
														marginBottom: '8px',
														paddingLeft: '12px',
														borderLeft: '3px solid #eee'
													}}
												>
													{index + 1}. {question.questionText}
												</Typography>
												<FormControl sx={{ ml: 3, mt: 1 }}>
													<RadioGroup
														row
														value={answers[question.questionId]?.answer || ''}
														onChange={(e) => handleAnswerChange(question.questionId, e.target.value)}
													>
														<FormControlLabel
															value="evet"
															control={<Radio size="small" />}
															label="Evet"
														/>
														<FormControlLabel
															value="hayir"
															control={<Radio size="small" />}
															label="Hayır"
														/>
													</RadioGroup>
												</FormControl>
												<TextField
													fullWidth
													size="small"
													placeholder="Açıklama"
													variant="outlined"
													value={answers[question.questionId]?.description || ''}
													onChange={(e) => handleDescriptionChange(question.questionId, e.target.value)}
													sx={{ mt: 1, ml: 3 }}
												/>
											</Box>
										))}
									</AccordionDetails>
								</Accordion>
							))}
						</div>
						<DialogActions>
							<Button
								sx={{marginLeft:"4px"}}
								onClick={() => console.log(answers)}
							>
								Kaydet
							</Button>
							<Button onClick={() => setFormDialogOpen(false)}>
								Kapat
							</Button>
						</DialogActions>
					</DialogContent>
				</Dialog>
			</React.Fragment>
		);
	}

	const center: LatLngExpression = [38.9637, 35.2433];
	return (
		<React.Fragment>
			<TileLayer
				url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				attribution=''
			/>
			{!filters.city ? (events && <CityBounds/>)
				:
				(events && <PinBounds/>)
			}
		</React.Fragment>
	)
}

export default Map;