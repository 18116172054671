import _ from 'lodash';
import {createTheme, ThemeOptions} from '@mui/material/styles';
import {useEffect} from 'react';
import components from './Components';
import typography from './Typography';
import * as locales from '@mui/material/locale';
import appStyles from "../constants/appStyles";

export const BuildTheme = (config: any = {}) => {

	const themeOptions: ThemeOptions = {
		palette: {
			primary: {
				main: '#094353',
			},
			secondary: {
				main: '#ad7303',
			},
			background: {
				default: '#fbf7f2',
				paper: '#ffffff',
			},
			warning: {
				main: '#cb7300',
			},
		},
		components: {
			MuiButtonBase: {
				styleOverrides: {
					root: {
						backgroundColor: "#000",
						"&:hover": {
							backgroundColor: "#fff"
						}
					}
				}
			},
			MuiMenuItem: {
				styleOverrides: {
					root: {
						background: 'transparent',
					}
				}
			}
		}


	};


	const baseMode = {
		shape: {
			borderRadius: 7,
		},
		typography: typography,
	};
	const theme = createTheme(
		_.merge({}, baseMode, locales, themeOptions, {
			direction: config.direction,
		}),
	);
	theme.components = components(theme);

	return theme;
};

const ThemeSettings = () => {
	const activeDir = appStyles.activeDir;
	const activeTheme = appStyles.activeTheme;
	const theme = BuildTheme({
		direction: activeDir,
		theme: activeTheme,
	});
	useEffect(() => {
		document.dir = activeDir;
	}, [activeDir]);

	return theme;
};

export {ThemeSettings};
