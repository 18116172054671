import ApiBase from '../apiBase';

const pageSize = 100000;

const EventsApi = {

	getEvents(params?: any) {
		return ApiBase.getPromise('/api/Events', params);
	},
	getPins() {
		return ApiBase.getPromise('/api/Pins', {pageSize});
	},
	getLocations() {
		return ApiBase.getPromise('/api/Locations', {pageSize});
	},
	getActorCategories() {
		return ApiBase.getPromise('/api/ActorCategories', {pageSize});
	},
	getEventTypes() {
		return ApiBase.getPromise('/api/EventTypes', {pageSize});
	},
	createNewPin(body: FormData) {
		return ApiBase.postPromiseFormData('/api/Pins/uploadQuestionsJson', body);
	},
	getDistrictBounds() {
		return fetch('https://inttechparser.blob.core.windows.net/citydistrictboundaries/ilce-sinirlari.json')
			.then((response) => response.json())
			.catch((error) => {
				console.error(error);
			});
	},


};

export default EventsApi;
