import {AppAction} from "../actions/appActions";

export interface IActorCategory {
	label: string;
	value: string;
	description: string;
}

export interface IEventType {
	label: string;
	value: string;
}

interface AppState {
	cities: string[] | null;
	actorCategories: IActorCategory[] | null;
	eventTypes: IEventType[] | null;
	districtBounds: any | null;
}

const initialState: AppState = {
	cities: null,
	actorCategories: null,
	eventTypes: null,
	districtBounds: null,
};

const appReducer = (state = initialState, action: AppAction): AppState => {
	switch (action.type) {
		case 'SAVE_CITIES':
			return {...state, cities: action.payload};
		case 'SAVE_ACTOR_CATEGORIES':
			return {...state, actorCategories: action.payload};
		case 'SAVE_EVENT_TYPES':
			return {...state, eventTypes: action.payload};
		case 'SAVE_DISTRICT_BOUNDS':
			return {...state, districtBounds: action.payload};
		case 'RESET_APP_CACHE':
			return initialState;
		default:
			return state;
	}
};

export default appReducer;