export const formData = {
    "categories": [
    {
        "categoryId": "category_1",
        "categoryName": "KORUMA VE GÜVENLİK PLANI",
        "questions": [
            {
                "questionId": "category_1_1",
                "questionText": "Tesis koruma ve güvenlik programı oluşturulmuş mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_1_2",
                "questionText": "Tesis koruma ve güvenlik programı Valilik  onaylı mı?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_1_3",
                "questionText": "Tesis koruma ve güvenlik programı güncel mi?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_1_4",
                "questionText": "Özel güvenlik şirketi sağladığı koruma ve güvenlik hizmetini, hizmetin başladığı gün mesai saati bitimine kadar Valiliğe yazılı olarak bildirmiş mi?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_1_5",
                "questionText": "Özel güvenlik hizmeti alan yere ait koruma ve güvenlik planı 30 gün içinde Valiliğe verilmiş mi?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_1_6",
                "questionText": "Göreve başlayan özel güvenlik görevlileri 15 gün içerisinde Valiliğe bildirilmiş mi?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_1_7",
                "questionText": "Görevden ayrılan özel güvenlik görevlileri 15 gün içerisinde Valiliğe bildirilmiş mi?",
                "answer": "",
                "description": ""
            }
        ]
    },
    {
        "categoryId": "category_2",
        "categoryName": "İDARİ",
        "questions": [
            {
                "questionId": "category_2_1",
                "questionText": "Hizmet alan yerin özel güvenlik izni mevcut mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_2_2",
                "questionText": "Sözleşme tarihi ve imzalar tam ve doğru mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_2_3",
                "questionText": "Hizmet sözleşmesi valilik teslim yazısı var mı?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_2_4",
                "questionText": "Personel bildirimleri onaylı mı? Dosyada mevcut mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_2_5",
                "questionText": "Özel güvenlik görevlileri için “özel güvenlik mali sorumluluk sigortası” yaptırılmış mı? Mali mesuliyet sigortaları dosyada mevcut mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_2_6",
                "questionText": "İB onaylı kıyafet kataloğu dosyada mevcut mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_2_7",
                "questionText": "Özel güvenlik şirketi çalışan özel güvenlik görevlilerinin kimlik kartlarının bir örneğini bir ay içerisinde koruma ve güvenlik hizmeti sağladığı yerin yetkilisine vermiş mi?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_2_8",
                "questionText": "Özel güvenlik görevlisi ile iş sözleşmesi yapılmış mı? Personel iş sözleşmeleri dosyada mevcut mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_2_9",
                "questionText": "Özel güvenlik görevlisinin geçici olarak başka yerde görevlendirilmesi yapılmış mı?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_2_10",
                "questionText": "Geçici olarak görevlendirilen özel güvenlik görevlisinin Valiliğe bildirimi yapılmış mı?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_2_11",
                "questionText": "SGK bildirim çizelgesi var mı?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_2_12",
                "questionText": "Kalite yönetim dökümanlar mevcut mu? (görev talimatları, prosedürler, formlar)",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_2_13",
                "questionText": "Vardiya planı mevcut mu? Nöbettekiler ile uyuşuyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_2_14",
                "questionText": "Vardiya devir teslimlerinde kontroller tam yapılıyor mu? Bilgi aktarımı eksiksiz yapılıyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_2_15",
                "questionText": "Şirketin yolladığı güncel talimat ve direktifler imza karşılığı tebliğ ediyor mu? Dosyalanıyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_2_16",
                "questionText": "Ziyaretçi, Araç , Telsiz, Teçhizat, Silah, Nöbet, Vardiya kayıt defterleri usulüne uygun tutulmakta mı? Eksik bilgi var mı? İmzalar tam mı?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_2_17",
                "questionText": "Olaylar sıralı amirlere ve müşteriye zamanında aktarılıyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_2_18",
                "questionText": "Olaylar ve aksaklıklarla ilgili güvenlik raporları düzenleniyor mu? Dosyalanıyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_2_19",
                "questionText": "Görev alanında kimyasal (biber gazı vb.) kullanılmak üzere komisyonu izni var mı ?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_2_20",
                "questionText": "Denetleme formundaki sorulara güncel cevap verecek şekilde denetleme dosyası oluşturulmuş mu?",
                "answer": "",
                "description": ""
            }
        ]
    },
    {
        "categoryId": "category_3",
        "categoryName": "RİSK VE TEHDİT DEĞERLENDİRME",
        "questions": [
            {
                "questionId": "category_3_1",
                "questionText": "Risk ve Tehdit değerlendirme Prosedürü mevcut mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_3_2",
                "questionText": "Personel Risk ve Tehdit Değerlendirme Prosedürüne uygun eğitimleri alıyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_3_3",
                "questionText": "Risk ve Tehdit Değerlendirmeleri yapılıyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_3_4",
                "questionText": "Risk ve Tehdit Değerlendirme kayıtları tutuluyor mu? (En son ne zaman yapıldı?)",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_3_5",
                "questionText": "Risk ve Tehdit Değerlendirme sonuçları ilgili departmanlara/kurumlara bildiriliyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_3_6",
                "questionText": "Hangi sıklıkta Risk ve Tehdit Değerlendirmeleri yapılıyor?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_3_7",
                "questionText": "En son uygulanan Risk ve Tehdit Değerlendirme sonuç raporunda tespit edilen eksiklikleri gidermek için öngörülen düzeltici faaliyetler yapılmış mı?",
                "answer": "",
                "description": ""
            }
        ]
    },
    {
        "categoryId": "category_4",
        "categoryName": "ÇEVRE GÜVENLİĞİ / YERLEŞKE",
        "questions": [
            {
                "questionId": "category_4_1",
                "questionText": "Tesisin  civarında suç oranı yüksek görülen yerleşkeler var mı?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_4_2",
                "questionText": "Tesise yakın herhangi bir Kolluk Gücü veya önemli bir devlet kuruluşu var mı?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_4_3",
                "questionText": "Tesise yakın önemli herhangi bir stratejik kuruluş var mı?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_4_4",
                "questionText": "Yetkisiz girişleri engellemek üzere çevre güvenlik unsurları ( tel örgü / duvar ) mevcut mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_4_5",
                "questionText": "Girişler yetkisiz kişilerin ve araçların girişini engelleyecek biçimde oluşturulmuş mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_4_6",
                "questionText": "Çevre güvenlik unsurları yapı itibariyle tırmanmaya elverişli mi?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_4_7",
                "questionText": "Çevre güvenlik unsurlarının yakınlarında tırmanmayı kolaylaştırıcı unsurlar var mı?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_4_8",
                "questionText": "Genel çevre görüşünü kısıtlayan / engelleyen manialar var mı?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_4_9",
                "questionText": "Tesisteki mimari değişiklikler ve inşaat çalışmaları yapılmadan önce güvenlik departmanı/ firması ile görüşülüyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_4_10",
                "questionText": "Tesis içerisinde Kritik olarak değerlendirilen bölümler mevcut mu?",
                "answer": "",
                "description": ""
            }
        ]
    },
    {
        "categoryId": "category_5",
        "categoryName": "GİRİŞ YETKİLENDİRMELERİ",
        "questions": [
            {
                "questionId": "category_5_1",
                "questionText": "Tesise ve Kritik alanlara giriş için gerekli giriş kontrol sistemi oluşturulmuş mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_5_2",
                "questionText": "Hangi belgelere/gerekçelere göre tesis girişine izin veriliyor?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_5_3",
                "questionText": "Kişilerin tesise giriş yaparken yetkisi olup olmadığı kontrol ediliyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_5_4",
                "questionText": "Diğer kimlik ve giriş kartlarında hangi bilgi ya da bilgiler doğrulanıyor?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_5_5",
                "questionText": "Tesisdeki  manuel mi yoksa elektronik bir geçiş sistemi kullanılıyor?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_5_6",
                "questionText": "Kritik alanlara açılan kapılar kontrol altına alınmış mı?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_5_7",
                "questionText": "Kritik alanlara açılan kapılar birden fazla kişinin aynı anda geçmesine izin veriyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_5_8",
                "questionText": "Turnikeli kapı yada kapılar bulunuyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_5_9",
                "questionText": "Turnikelerden kişilerin art arda geçmesini önlemek için gerekli tedbirler alınmış mı?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_5_10",
                "questionText": "Giriş kartıyla Kritik alanlara birden fazla geçiş yapılmasını engelleyici prosedür oluşturulmuş mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_5_11",
                "questionText": "Özel araçlar Kritik alanlara giriş yapabiliyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_5_12",
                "questionText": "Araç geçiş plakaları gözle mi yoksa elektronik bir sistemle mi kontrol ediliyor?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_5_13",
                "questionText": "Tesis giriş kartı verilen tüm personele özgeçmiş araştırması yapılıyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_5_14",
                "questionText": "Geçici Tesis giriş kartı verilen kişilere özgeçmiş araştırması yapılıyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_5_15",
                "questionText": "Özgeçmiş araştırması olumsuz sonuçlanan personelin Tesis giriş kartı iptal ediliyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_5_16",
                "questionText": "Özgeçmiş araştırması ne kadar süreyle tekrarlanıyor?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_5_17",
                "questionText": "Kritik alanlarada bulunan kişiler Tesis giriş kartlarını görünür bir şekilde takıyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_5_18",
                "questionText": "Biyometrik Giriş Kontrol sistemi mevcut mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_5_19",
                "questionText": "Biyometrik Giriş Kontrol Sistemi prosedürü oluşturulmuş mu?",
                "answer": "",
                "description": ""
            }
        ]
    },
    {
        "categoryId": "category_6",
        "categoryName": "GÜVENLİK KONTROLLERİ",
        "questions": [
            {
                "questionId": "category_6_1",
                "questionText": "Güvenlik kontrolleri için prosedür oluşturulmuş mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_6_2",
                "questionText": "Güvenlik cihazları kullanım prosedürü oluşturulmuş mu ( KTMD, ETD, X-RAY vb.) ?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_6_3",
                "questionText": "Güvenlik tarama noktalarında görevlendirilen personel uygun eğitimler sonrası sertifikalandırılmış mı?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_6_4",
                "questionText": "Güvenlik tarama prosedürüne uygun hareket ediliyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_6_5",
                "questionText": "Güvenlik tarama noktalarında uygun ekipman kullanılıyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_6_6",
                "questionText": "Güvenlik tarama noktalarında patlayıcı tespit köpeği kullanılıyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_6_7",
                "questionText": "Güvenlik tarama noktalarında patlayıcı iz tespit dedektörü kullanılıyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_6_8",
                "questionText": "Dozimetre (radyasyon ölçer ) kullanımı gerektiren bir durum var mı?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_6_9",
                "questionText": "Güvenlik Ekipmanları test ediliyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_6_10",
                "questionText": "Kontrol noktalarında uygun sayıda personel bulunuyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_6_11",
                "questionText": "Kontrol noktalarında bay-bayan personel görevlendirmesi yapılıyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_6_12",
                "questionText": "Kritik alanlara giriş yapan personel uygun olarak taranıyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_6_13",
                "questionText": "Personel sürekli rastgele tespit usulüne göre taranıyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_6_14",
                "questionText": "Personelin yanında taşıdığı ve şahsi eşyalarının tamamı taranıyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_6_15",
                "questionText": "Yasaklı madde uygulaması mevcut mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_6_16",
                "questionText": "Yasaklı maddelerle ilgili prosedür oluşturulmuş mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_6_17",
                "questionText": "İzin verilen yasaklı maddelerin listesi her giriş noktasında bulunuyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_6_18",
                "questionText": "Taramadan muaf tutulan kişiler var mı?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_6_19",
                "questionText": "Tesiste kolluk kuvveti görev yapıyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_6_20",
                "questionText": "Tesiste çalışan kolluk kuvvetleri taramadan muaf tutuluyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_6_21",
                "questionText": "Şüpheli araç, şüpheli paket ve şüpheli şahıs tespitinde hareket tarzı biliniyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_6_22",
                "questionText": "Bomba ihbar formu kolay erişilebilir yerde mi? Personel ihbar ve tehdit telefonu aldığında yapacaklarını biliyor mu?",
                "answer": "",
                "description": ""
            }
        ]
    },
    {
        "categoryId": "category_7",
        "categoryName": "ARAÇ GİRİŞLERİ",
        "questions": [
            {
                "questionId": "category_7_1",
                "questionText": "Araç araması ile ilgili prosedür oluşturulmuş mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_7_2",
                "questionText": "Araç Giriş Noktaları Yasadışı olayları caydıracak nitelikte oluşturulmuş mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_7_3",
                "questionText": "Tesise araç girişine izin veriliyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_7_4",
                "questionText": "Tesisdeki  kaç tane araç giriş kapısı bulunuyor?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_7_5",
                "questionText": "Giriş yapan tüm araçlar aranıyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_7_6",
                "questionText": "Araç içindeki kişi/kişiler taranıyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_7_7",
                "questionText": "Araç içindeki kişi/kişilerin eşyaları taranıyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_7_8",
                "questionText": "Araç aramasında hangi yöntem/yöntemler kullanılıyor?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_7_9",
                "questionText": "Araç giriş noktasında araçları aramak için kullanılan özel alet/aletler bulunuyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_7_10",
                "questionText": "Araç arama için patlayıcı tespit köpeği kullanılıyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_7_11",
                "questionText": "Araç arama için patlayıcı iz tespit detektörü kullanılıyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_7_12",
                "questionText": "Aramadan muaf tutulan araçlar var mı?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_7_13",
                "questionText": "Aramadan muaf tutulan araçlara refakat ediliyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_7_14",
                "questionText": "Tesisdeki  çalışan silahlı kolluk kuvvetleri var ise  araçları aramadan muaf tutuluyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_7_15",
                "questionText": "Tesise mühürlü giriş yapan araç var mı?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_7_16",
                "questionText": "Araçlardaki mühürün doğrulaması nasıl yapılıyor?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_7_17",
                "questionText": "Mühürlü araç kontrolleri hangi usulleri kapsıyor?",
                "answer": "",
                "description": ""
            }
        ]
    },
    {
        "categoryId": "category_8",
        "categoryName": "ZİYARETÇİ SİSTEMİ",
        "questions": [
            {
                "questionId": "category_8_1",
                "questionText": "Ziyaretçi kayıtlarına erişim prosedürü oluşturulmuş mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_8_2",
                "questionText": "Ziyaretçiler tesis girişinde Güvenlik taramasından geçiriliyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_8_3",
                "questionText": "Ziyaretçiler için bir kayıt sistemi geliştirilmiş mi?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_8_4",
                "questionText": "Ziyaretçilerin tesis içerisinde takmaları zorunlu olan  \"Tanıtma Kartı\" uygulaması var mı?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_8_5",
                "questionText": "Ziyaretçilere gideceği yere kadar refakat ediliyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_8_6",
                "questionText": "Ziyaretçilere çıkışa kadar refakat ediliyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_8_7",
                "questionText": "Ziyaretçiler tesisin tamamına erişim sağlayabiliyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_8_8",
                "questionText": "Ziyaretçi Tanıtma Kartında \"Alan Yetkilendirmesi\" yapılıyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_8_9",
                "questionText": "Ziyaretçilerin hangi alanlara erişim sağlayabileceğine yönelik bir sistem oluşturulmuş mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_8_10",
                "questionText": "Ziyaretçiler tesis çıkışı öncesi Güvenlik taramasından geçiriliyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_8_11",
                "questionText": "Ziyaretçi kayıtlarına erişim kısıtlandırılmış mı?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_8_12",
                "questionText": "Ziyaretçi kayıtları arşivleniyor mu?",
                "answer": "",
                "description": ""
            }
        ]
    },
    {
        "categoryId": "category_9",
        "categoryName": "MAL KABUL / POSTA - KARGO",
        "questions": [
            {
                "questionId": "category_9_1",
                "questionText": "Mal Kabul kabulüne ilişkin prosedür oluşturulmuş mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_9_2",
                "questionText": "Mal Kabul Kontrolleri için ayrı bir kontrol noktası oluşturulmuş mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_9_3",
                "questionText": "Mal kabul noktasında bariyer engel mevcut mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_9_4",
                "questionText": "Mal kabul noktasında özel güvenlik personeli var mı veya başka bir metot ile kontrol altında mı?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_9_5",
                "questionText": "Gelen mal/ürün vb. kontrollerden geçiyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_9_6",
                "questionText": "Firmalar yetkilileri giriş esnasında kayıt yaptırıyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_9_7",
                "questionText": "Tutulan kayıtlar düzenli olarak kontrol ediliyor mu? Kim tarafından?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_9_8",
                "questionText": "Kargo ve Posta kabulüne ilişkin prosedür oluşturulmuş mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_9_9",
                "questionText": "Kargo ve Postalar güvenlik kontrollerine tabi tutuluyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_9_10",
                "questionText": "Kargo ve Postalar sahibine teslim edilene kadar gözetim altında tutuluyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_9_11",
                "questionText": "Kargo-Posta kuryelerinin tesis içerisine girşine izin veriliyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_9_12",
                "questionText": "Kargo ve Posta  taramasında Patlayıcı Tespit Köpeği kullanılıyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_9_13",
                "questionText": "Kargo ve Posta  taramasında Patlayıcı İz Tespit Detektörü kullanılıyor mu?",
                "answer": "",
                "description": ""
            }
        ]
    },
    {
        "categoryId": "category_10",
        "categoryName": "AYDINLATMA",
        "questions": [
            {
                "questionId": "category_10_1",
                "questionText": "Tesisin etrafı için yeterli aydınlatma mevcut mu ?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_10_2",
                "questionText": "Tesis sınırları yeterince aydınlatılıyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_10_3",
                "questionText": "Tesisin içerisi için yeterli aydınlatma mevcut mu ?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_10_4",
                "questionText": "Hassas bölgeler, otoparklar vs. yeterince aydınlatılıyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_10_5",
                "questionText": "Çevre Aydınlatmaları müdahale edilmesini engelleyecek yükseklikte mi?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_10_6",
                "questionText": "Aydınlatma sistemlerini güç kesintisi durumunda destekleyen jeneratör mevcut mu?",
                "answer": "",
                "description": ""
            }
        ]
    },
    {
        "categoryId": "category_11",
        "categoryName": "CCTV",
        "questions": [
            {
                "questionId": "category_11_1",
                "questionText": "CCTV Merkezi \"Giriş Prosedürü\" oluşturulmuş mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_11_2",
                "questionText": "CCTV Merkezinde yeterli personel görevlendirilmiş mi?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_11_3",
                "questionText": "CCTV sistemi ve İzleme odası mevcut mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_11_4",
                "questionText": "Çevre güvenlik kamerası mevcut mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_11_5",
                "questionText": "Çevre güvenlik kameraları gece görüş özelliğine sahip mi?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_11_6",
                "questionText": "Çevre güvenlik kameralarının görüş alanları yeterince aydınlatılmış mı?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_11_7",
                "questionText": "Çevre güvenlik kameraları birbirini destekleyecek şekilde mi konuşlandırılmış?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_11_8",
                "questionText": "Çevre güvenlik kameraları teknik olarak yeterli mi? ( çözünürlük vs. )",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_11_9",
                "questionText": "Tesis içi güvenlik kamerası mevcut mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_11_10",
                "questionText": "Tesis içi güvenlik kameraları gece görüş özelliğine sahip mi?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_11_11",
                "questionText": "Tesis içi güvenlik kameralarının görüş alanları yeterince aydınlatılmış mı?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_11_12",
                "questionText": "Tesis içerisinde güvenlik kameralarının kapsamadığı kritik alanlar mevcut mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_11_13",
                "questionText": "Tesis içi güvenlik kameraları teknik olarak yeterli mi? ( çözünürlük vs. )",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_11_14",
                "questionText": "Kamera sistemi hareket senserü-infrared bariyerler vs. ile desteklenmiş mi?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_11_15",
                "questionText": "Kamera sistemi kayıt yapıyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_11_16",
                "questionText": "Kamera kayıtlarına erişim kısıtlanmış mı?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_11_17",
                "questionText": "Kamera kayıtlarına erişim prosedürü oluşturulmuş mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_11_18",
                "questionText": "Kamera kayıt süresi yeterli mi?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_11_19",
                "questionText": "CCTV merkezine entegre Alarm Sistemi mevcut mu?",
                "answer": "",
                "description": ""
            }
        ]
    },
    {
        "categoryId": "category_12",
        "categoryName": "DEVRİYE",
        "questions": [
            {
                "questionId": "category_12_1",
                "questionText": "Devriye sistemi var mı?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_12_2",
                "questionText": "Devriyeler ne kadar sıklıkla gerçekleştiriliyor?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_12_3",
                "questionText": "Elektronik Devriye Takip Sistemi mevcut mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_12_4",
                "questionText": "Elektronik Devriye Takip sistem çıktıları değerlendiriliyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_12_5",
                "questionText": "Devriye için görevlendirilmiş yeterli mi?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_12_6",
                "questionText": "Devriye için görevlendirilmiş araç var mı? ( adet )",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_12_7",
                "questionText": "Devriye güzergahı uygun mu?",
                "answer": "",
                "description": ""
            }
        ]
    },
    {
        "categoryId": "category_13",
        "categoryName": "GÜVENLİK PERSONELİ",
        "questions": [
            {
                "questionId": "category_13_1",
                "questionText": "Tesiste Güvenlik Personeli çalıştırılıyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_13_2",
                "questionText": "Güvenlik Personelinin çalışma usulünü içeren prosedür var mı?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_13_3",
                "questionText": "Güvenlik personeli Kalite Yönetim Sistemi dokümanlarına göre görev yapıyor mu? Gerekli formları kullanıyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_13_4",
                "questionText": "Güvenlik personelinin görev yaptığı noktalarda Görev ve Görev Yeri talimatları asılı mı / dosyada mı?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_13_5",
                "questionText": "Güvenlik personeli için iş akış ve görevlendirme talimatı var mı?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_13_6",
                "questionText": "Güvenlik personelinin işe alınması için kriterler var mı?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_13_7",
                "questionText": "Güvenlik Personeline GBT araştırması yapılıyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_13_8",
                "questionText": "Güvenlik personeline mesleki eğitimler veriliyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_13_9",
                "questionText": "Güvenlik personelinin eğitim kayıtları saklanıyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_13_10",
                "questionText": "Güvenlikten sorumlu olarak görevlendirilen personelin yerine bakacak bir başka personelde belirlenmiş mi?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_13_11",
                "questionText": "Güvenlikten sorumlu olarak görevlendirilen personelin sorumlulukları belirlenmiş mi?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_13_12",
                "questionText": "GBT araştırmaları periyodik olarak tekrarlanıyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_13_13",
                "questionText": "Özel güvenlik görevlisi çalışma esnasında görev alanı sınırlarına riayet ediyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_13_14",
                "questionText": "Özel güvenlik görevlisi, görev esnasında koruma ve güvenlik hizmetleri dışında çalıştırılıyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_13_15",
                "questionText": "Özel güvenlik görevlisi Bakanlık tarafından onaylanan kıyafet ve teçhizatla mı görev yapmaktadır?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_13_16",
                "questionText": "Özel güvenlik görevlilerinin, özel güvenlik görevlisi kimlik kartı mevcut mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_13_17",
                "questionText": "Özel güvenlik görevlisi kimlik kartıgörünür şekilde takılı mı?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_13_18",
                "questionText": "Personel üniforma ve teçhizatları talimatlara uygun mu? Tam mı?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_13_19",
                "questionText": "Devir teslimler kayıt altına alınıyor mu? Teçhizat kontrolu eksiksiz yapılıyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_13_20",
                "questionText": "Tesisin kritik noktaları biliniyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_13_21",
                "questionText": "Personelin görev noktası tertipli ve düzenli mi? TV, radyo, gazete vb. malzeme var mı?",
                "answer": "",
                "description": ""
            }
        ]
    },
    {
        "categoryId": "category_14",
        "categoryName": "SİLAHLI GÜVENLİK PERSONELİ",
        "questions": [
            {
                "questionId": "category_14_1",
                "questionText": "Özel güvenlik hizmeti alan yerde ateşli silah mevcut mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_14_2",
                "questionText": "Silah Taşıma / Bulundurma belgeleri alınmış mı?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_14_3",
                "questionText": "Silahlı görev yapan özel güvenlik görevlisi silah taşıma/bulundurma belgesini yanında taşımakta mı?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_14_4",
                "questionText": "Silahlı görev yapan özel güvenlik görevlisi silahını görev alanında taşımakta mıdır?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_14_5",
                "questionText": "Silahlı görev yapan özel güvenlik görevlisi silahını görev alanı dışına çıkarmakta mı?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_14_6",
                "questionText": "Silah demirbaş  defterleri usulüne uygun  kayıt altnda tulmakta mı? İmzalar ve bilgiler tam mı?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_14_7",
                "questionText": "Silah devir teslimleri günlük olarak Devir Teslim ve Rapor Defterine kayıt edilmiş mi? İmzalar & tarihler tam mı ? Silah Devir Teslim ve Rapor Defteri günlük olarak imzalanıyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_14_8",
                "questionText": "Silahlar, fişekler ve teçhizatları 5188 sayılı Kanunun uygulama Yönetmeliğinin 25. maddesinde belirtilen şartlarda muhafaza ediliyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_14_9",
                "questionText": "Silahların bakımları yapılıyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_14_10",
                "questionText": "Silahlı görev yapan özel güvenlik görevlilerine her yıl en az 25 fişek üzerinden hizmet içi eğitim atışı yaptırılmış mı? Eğitim kayıtları?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_14_11",
                "questionText": "Herhangi bir nedenle özel güvenlik hizmeti alan yerden çıkartılan silahlar için silah nakil belgesi alınmış mı?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_14_12",
                "questionText": "Silahlı güvenlik personeli \"Silahlı Görev\"  ile ilgili tüm  Kaliye Yönetim Sistemi dokümanlarını tebliğ etmiş mi?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_14_13",
                "questionText": "Doldur-boşlat istasyonu var mı?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_14_14",
                "questionText": "Doldur-boşlat istasyonu talimatı var mı ? Bulunduğu bölgesde asılı mı?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_14_15",
                "questionText": "Görev devir-teslimlerinden önce doldur-boşalt  nezaretçi tarafından yaptırılmakta mı?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_14_16",
                "questionText": "Doldur-boşalt defteri usulüne uygun kayıt altına alınmakta mı? imzalar & tarihler tam mı ?",
                "answer": "",
                "description": ""
            }
        ]
    },
    {
        "categoryId": "category_15",
        "categoryName": "TESİS PERSONELİ",
        "questions": [
            {
                "questionId": "category_15_1",
                "questionText": "Tesiste yeni işbaşı yapacak / mevcut çalışan personelin GBT araştırmaları yapılıyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_15_2",
                "questionText": "Tesiste çalışan tüm personele Güvenlik Bilinci, Terör, Şüpheli Durumlar - Şahıslar vb. eğitimleri veriliyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_15_3",
                "questionText": "GBT araştırmaları periyodik olarak tekrarlanıyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_15_4",
                "questionText": "Sendikal faaliyetler var mı ? Önceden yaşanmış olaylar var mı?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_15_5",
                "questionText": "Çalışanlara sorumluluklarına göre anahtar/kart yetkilendirilmeleri yapılmış mı?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_15_6",
                "questionText": "Çalışanlar ara saatlerde tesisi bırakıp ayrılıyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_15_7",
                "questionText": "Personel seçimi için bizden mülakat hizmeti talebi var mı?",
                "answer": "",
                "description": ""
            }
        ]
    },
    {
        "categoryId": "category_16",
        "categoryName": "İLETİŞİM",
        "questions": [
            {
                "questionId": "category_16_1",
                "questionText": "Görevli personelin kendi aralarında iletişim kuracakları telsiz/telefon vb. sistemler mevcut mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_16_2",
                "questionText": "Güvenlik personelinin diğer departmanlar ve kolluk kuvvetleri ile iletişimi mevcut mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_16_3",
                "questionText": "İletişim araçlarının kapsama alanı yeterli mi?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_16_4",
                "questionText": "İletişim için özel kodlar var mı?",
                "answer": "",
                "description": ""
            }
        ]
    },
    {
        "categoryId": "category_17",
        "categoryName": "ACİL DURUM / DOĞAL AFETLER",
        "questions": [
            {
                "questionId": "category_17_1",
                "questionText": "Acil Durum eylem prosedürü oluşturulmuş mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_17_2",
                "questionText": "Acil Durum Planı Koruma ve Güvenlik Planına uygun mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_17_3",
                "questionText": "Acil Durum tatbikatları yapılıyor mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_17_4",
                "questionText": "En son Güvenlik tatbikatı ne zaman yapılmış?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_17_5",
                "questionText": "Kriz Merkezi / Yönetimi oluşturulmuş mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_17_6",
                "questionText": "\"Acil Durum İletişim\" prosedürü mevcut mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_17_7",
                "questionText": "Acil durumlarda aranacaklar listesi görünür yerde asılı mı?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_17_8",
                "questionText": "ÖGG veya çalışanlar tarafından yangın tüpleri, yangın dolapları ve hidrantların yerleri ve kullanımları biliniyor mu?",
                "answer": "",
                "description": ""
            }
        ]
    },
    {
        "categoryId": "category_18",
        "categoryName": "ÜRETİM VE  DEPOLAMA",
        "questions": [
            {
                "questionId": "category_18_1",
                "questionText": "Üretim ve depolama güvenliği hakkında prosedür oluşturulmuş mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_18_2",
                "questionText": "Üretim ve depolama alanında yeterli güvenlik tedbirleri alınmış mı?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_18_3",
                "questionText": "Üretim ve depolama alanları için yeterli fiziki ve elektronik koruma tedbirleri var mı?",
                "answer": "",
                "description": ""
            }
        ]
    },
    {
        "categoryId": "category_19",
        "categoryName": "EKONOMİK DEĞERLER",
        "questions": [
            {
                "questionId": "category_19_1",
                "questionText": "Tesisde bulunan ekonomik değeri yüksek olan unsurların güvenliği hakkında prosedür oluşturulmuş mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_19_2",
                "questionText": "Değerli emtialar (para, altın vb.) muhafaza ediliyor mu?  Nasıl ? Nerede ?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_19_3",
                "questionText": "Marka değeri için önemli unsurlar muhafaza ediliyor mu?  Nasıl ? Nerede ?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_19_4",
                "questionText": "Gündüz/gece ne miktarda değerli emtia bırakılmakta?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_19_5",
                "questionText": "Değerli emtianın nakil işlemlerinde güvenlik tedbirleri alınıyor mu?",
                "answer": "",
                "description": ""
            }
        ]
    },
    {
        "categoryId": "category_20",
        "categoryName": "BİLGİ GÜVENLİĞİ",
        "questions": [
            {
                "questionId": "category_20_1",
                "questionText": "Tesisin bilgi işlem merkezi var mı?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_20_2",
                "questionText": "Bilgi güvenliği prosedürü oluşturulmuş mu?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_20_3",
                "questionText": "Değerli bilgi ve belgelerin tesis dışına çıkarılmaması için yeterli güvenlik tedbirleri alınmış mı?",
                "answer": "",
                "description": ""
            }
        ]
    },
    {
        "categoryId": "category_21",
        "categoryName": "HİZMET ALAN / OTORİTE AÇISINDAN ÖZEL GÜVENLİK TALEPLERİ",
        "questions": [
            {
                "questionId": "category_21_1",
                "questionText": "Yukarıda belirtilen kriterler dışında özel talepleri var mı?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_21_2",
                "questionText": "Tesis otoritesinin dile getirmiş olduğu özel hassaasiyetler ve güvenlik durumları var mı?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_21_3",
                "questionText": "Daha önce yapılmış risk ve tehdit değerlendirmeleri sonuçlarına göre aksiyon alınmış mı?",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_21_4",
                "questionText": "Yazılı, görsel ve diğer kaynaklardan takibi gereken hususlar var mı?",
                "answer": "",
                "description": ""
            }
        ]
    },
    {
        "categoryId": "category_22",
        "categoryName": "ELEKTRONİK GÜVENLİK TEDBİRLERİ",
        "questions": [
            {
                "questionId": "category_22_1",
                "questionText": "HAREKETE DUYARLI KAMERALAR",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_22_2",
                "questionText": "GECE GÖRÜŞ KAMERASI",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_22_3",
                "questionText": "ISIYA DUYARLI KAMERA",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_22_4",
                "questionText": "SABİT KAMERA",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_22_5",
                "questionText": "DOME KAMERA",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_22_6",
                "questionText": "MİKRO KAMERA",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_22_7",
                "questionText": "PARMAK İZİ CİHAZI",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_22_8",
                "questionText": "YÜZ TARAMA CİHAZI",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_22_9",
                "questionText": "GÖZ RETİNA TARAMA CİHAZI",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_22_10",
                "questionText": "KAPI TİPİ DETEKTÖR",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_22_11",
                "questionText": "X RAY CİHAZI KÜÇÜK KÜÇÜK - ORTA - BÜYÜK BOY",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_22_12",
                "questionText": "UPS CİHAZI",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_22_13",
                "questionText": "KAMERA KAYIT CİHAZI SİSTEMLERİ",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_22_14",
                "questionText": "LAZER BARİYER SİSTEMLERİ",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_22_15",
                "questionText": "HIRSIZ SENSÖRÜ PIR CİHAZI",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_22_16",
                "questionText": "DARBE SENSÖRLERİ",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_22_17",
                "questionText": "GAZ DEDEKTÖRÜ",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_22_18",
                "questionText": "DUMAN DEDEKTÖRÜ",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_22_19",
                "questionText": "ACİL DURUM BUTONU",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_22_20",
                "questionText": "ELEKTRİKLİ TEL ÖRGÜ SİSTEMİ",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_22_21",
                "questionText": "TUR KONTROL KALEMİ",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_22_22",
                "questionText": "MOBİLE MOBESE SİSTEMİ",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_22_23",
                "questionText": "KOLLU BARİYER",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_22_24",
                "questionText": "YANGIN PERDESİ",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_22_25",
                "questionText": "KOLLU BARİYER",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_22_26",
                "questionText": "EL TİPİ DEDEKTÖRLER",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_22_27",
                "questionText": "MANTAR BARİYER",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_22_28",
                "questionText": "BÜYÜK BOY KALKAN BARİYER",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_22_29",
                "questionText": "ELEKTRONİK KİLİT",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_22_30",
                "questionText": "İZ DEDEKTÖRÜ",
                "answer": "",
                "description": ""
            }
        ]
    },
    {
        "categoryId": "category_23",
        "categoryName": "FİZİKİ GÜVENLİK TEDBİRLERİ",
        "questions": [
            {
                "questionId": "category_23_1",
                "questionText": "ŞEHİR MOBİLYALARI ( BETON SAKSILAR,TAŞ YAPIM HEYKELLER VB.. )",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_23_2",
                "questionText": "ÇEVRE AYDINLATMASI",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_23_3",
                "questionText": "TAM BOY TURNİKE",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_23_4",
                "questionText": "KANATLI DEMİR KAPI",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_23_5",
                "questionText": "BIÇAKLI TEL ÖRGÜ",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_23_6",
                "questionText": "SET TEL ÖRGÜ",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_23_7",
                "questionText": "KUVVETLİ PROJEKTÖR",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_23_8",
                "questionText": "MANUEL KİLİT SİSTEMLERİ",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_23_9",
                "questionText": "GÜVENLİK MOBOLARI",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_23_10",
                "questionText": "ELEKTRİKLİ DEMİR KAPILAR",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_23_11",
                "questionText": "UYARI LEVHALARI",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_23_12",
                "questionText": "DEMİR KAPANLAR",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_23_13",
                "questionText": "PORTATİF DEMİR BARİYERLER",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_23_14",
                "questionText": "ŞERİT BARİYERLER",
                "answer": "",
                "description": ""
            },
            {
                "questionId": "category_23_15",
                "questionText": "ELEKTRİKLİ JOP",
                "answer": "",
                "description": ""
            }
        ]
    }
]
};