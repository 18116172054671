import AppBar from "./AppBar";
import {Outlet} from "react-router-dom";
import {Paper} from "@mui/material";
import {AppDispatch, useSelector} from "../../../redux/store";
import {useEffect} from "react";
import eventsApi from "../../../api/eventsApi/eventsApi";
import {saveActorCategories, saveCities, saveDistrictBounds, saveEventTypes} from "../../../redux/actions/appActions";
import {desiredCities} from "../../../constants/consts";

interface Location {
	id: string;
	city: string;
	district1: string;
	district2: string;
	latitude: number;
	longitude: number;
}

const PageWrapper = () => {
	const {cities, actorCategories, eventTypes, districtBounds} = useSelector(state => state.app)
	const dispatch = AppDispatch();

	useEffect(() => {
		if (!cities) {
			eventsApi.getLocations().then((response: any) => {
				const cities: string[] = response.items
					.map((location: Location) => location.city)
					.filter((city: string) => desiredCities.includes(city))
				const uniqueCities = Array.from(new Set(cities));

				dispatch(saveCities(uniqueCities.sort((a: string, b: string) =>
					a.localeCompare(b, 'tr', {sensitivity: 'base'})
				)))
			}).catch(e => console.warn(e))
		}
		if (!actorCategories) {
			eventsApi.getActorCategories().then((response: any) => {
				dispatch(saveActorCategories(response.items.map((actor: any) => ({
					label: actor.name,
					value: actor.id,
					description: actor.description
				}))))
			}).catch(e => console.warn(e))
		}
		if (!eventTypes) {
			eventsApi.getEventTypes().then((response: any) => {
				dispatch(saveEventTypes(response.items.map((eventType: { name: any; id: any; }) => ({
					label: eventType.name,
					value: eventType.id
				}))))
			}).catch(e => console.warn(e))
		}
		if (!districtBounds) {
			eventsApi.getDistrictBounds().then((response: any) => {
				dispatch(saveDistrictBounds(response))
			}).catch(e => console.warn(e))
		}
	}, [cities, actorCategories, eventTypes, districtBounds]);

	return <>
		<AppBar/>
		<Paper sx={{
			display: "flex",
			width: {xs: "100%", md: "calc(100% - 2rem)"},
			height: 'calc(100% - 7rem)',
			marginX: {xs: ".2rem", md: "1rem"},
			marginTop: "1rem",
			paddingY: {xs: ".2rem", md: "2rem"},
			paddingX: {xs: ".2rem", md: "1rem", xl: "2rem"},
			backgroundColor: "white",
			alignItems: "start",
			overflowY: "scroll",
		}}>
			<Outlet/>
		</Paper>
	</>
}

export default PageWrapper