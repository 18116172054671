import "./spinner.css";
import {RootState, useSelector} from "../../redux/store";
import React from "react";

const Spinner: React.FC = () => {
	const loading = useSelector((state: RootState) => state.loading);

	return loading ? (
		<div className="spinner-overlay">
			<div className="spinner"/>
		</div>
	) : null;
};
export default Spinner;
