import React from 'react';
import Grid from "@mui/material/Grid";
import {
	List,
	ListItem,
	ListItemText,
	Paper,
	Typography,
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	DialogActions,
	Radio,
	RadioGroup,
	FormControlLabel,
	FormControl,
	TextField,
	Box
} from "@mui/material";
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {AccordionSummaryProps, accordionSummaryClasses} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import CloseIcon from '@mui/icons-material/Close';
import {formData} from "../../../assets/formData";


interface ISummaryProps {
	eventTypeCounts?: any[] | null,
	districtEventCounts?: any[] | null,
	startDate: Date,
	endDate: Date
}

interface Answer {
	answer: string;
	description: string;
}

interface FormAnswers {
	[key: string]: Answer;
}

const Summary = (props: ISummaryProps) => {
	const {eventTypeCounts, districtEventCounts, startDate, endDate} = props;
	const [expanded, setExpanded] = React.useState<string>("");
	const [modalOpen, setModalOpen] = React.useState(false);
	const [answers, setAnswers] = React.useState<FormAnswers>({});

	const handleAnswerChange = (questionId: string, value: string) => {
		setAnswers(prev => ({
			...prev,
			[questionId]: {
				...prev[questionId],
				answer: value
			}
		}));
	};
	const handleDescriptionChange = (questionId: string, value: string) => {
		setAnswers(prev => ({
			...prev,
			[questionId]: {
				...prev[questionId],
				description: value
			}
		}));
	};

	// Styled components
	const Accordion = styled((props: AccordionProps) => (
		<MuiAccordion disableGutters elevation={0} square {...props} />
	))(({ theme }) => ({
		border: `1px solid ${theme.palette.divider}`,
		'&:not(:last-child)': {
			borderBottom: 0,
		},
		'&::before': {
			display: 'none',
		},
	}));

	const AccordionSummary = styled((props: AccordionSummaryProps) => (
		<MuiAccordionSummary
			expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
			{...props}
		/>
	))(({ theme }) => ({
		backgroundColor: 'rgba(0, 0, 0, .03)',
		flexDirection: 'row-reverse',
		[`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]: {
			transform: 'rotate(90deg)',
		},
		[`& .${accordionSummaryClasses.content}`]: {
			marginLeft: theme.spacing(1),
		},
		...theme.applyStyles?.('dark', {
			backgroundColor: 'rgba(255, 255, 255, .05)',
		}),
	}));

	const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
		padding: theme.spacing(2),
		borderTop: '1px solid rgba(0, 0, 0, .125)',
	}));

	const handleChange = (panel: string) => (_event: React.SyntheticEvent, newExpanded: boolean) => {
		setExpanded(newExpanded ? panel : '');
	};

	return (
		<Paper sx={{padding: ".5rem"}}>

			<Dialog
				open={modalOpen}
				onClose={() => setModalOpen(false)}
				maxWidth="md"
				fullWidth
			>
				<DialogContent>
					<DialogTitle>RİSK ANALİZ FORMU</DialogTitle>
					<div>
						<Typography sx={{fontSize:"12px", paddingLeft:"24px", marginBottom:"12px"}}>
							Risk Analiz Formu {formData.categories.length} bölümden oluşmaktadır.
						</Typography>

						{formData.categories.map((category) => (
							<Accordion
								key={category.categoryId}
								expanded={expanded === category.categoryId}
								onChange={handleChange(category.categoryId)}
							>
								<AccordionSummary
									aria-controls={`${category.categoryId}-content`}
									id={`${category.categoryId}-header`}
								>
									<Typography>{category.categoryName}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									{category.questions.map((question, index) => (
										<Box key={question.questionId} sx={{ mb: 3 }}>
											<Typography
												sx={{
													fontSize: '14px',
													marginBottom: '8px',
													paddingLeft: '12px',
													borderLeft: '3px solid #eee'
												}}
											>
												{index + 1}. {question.questionText}
											</Typography>
											<FormControl sx={{ ml: 3, mt: 1 }}>
												<RadioGroup
													row
													value={answers[question.questionId]?.answer || ''}
													onChange={(e) => handleAnswerChange(question.questionId, e.target.value)}
												>
													<FormControlLabel
														value="evet"
														control={<Radio size="small" />}
														label="Evet"
													/>
													<FormControlLabel
														value="hayir"
														control={<Radio size="small" />}
														label="Hayır"
													/>
												</RadioGroup>
											</FormControl>
											<TextField
												fullWidth
												size="small"
												placeholder="Açıklama"
												variant="outlined"
												value={answers[question.questionId]?.description || ''}
												onChange={(e) => handleDescriptionChange(question.questionId, e.target.value)}
												sx={{ mt: 1, ml: 3 }}
											/>
										</Box>
									))}
								</AccordionDetails>
							</Accordion>
						))}
					</div>
					<DialogActions>
						<Button
							sx={{marginLeft:"4px"}}
							onClick={() => console.log(answers)} // Debug için cevapları console'a yazdırıyoruz
						>
							Kaydet
						</Button>
						<Button onClick={() => setModalOpen(false)}>
							Kapat
						</Button>
					</DialogActions>
				</DialogContent>
			</Dialog>

			<Typography variant={"h6"}>
				{`${startDate.toLocaleDateString('tr-TR')} - ${endDate.toLocaleDateString('tr-TR')} tarihleri arasında ${!!districtEventCounts ? "seçili bölge için " : ""} olay kayıtları.`}
			</Typography>

			<List>
				{!!eventTypeCounts && eventTypeCounts.map((item: any) => (
					<ListItem>
						<ListItemText
							primary={item.eventType}
							secondary={item.count}
						/>
					</ListItem>
				))}
			</List>
		</Paper>
	);
}

export default Summary;