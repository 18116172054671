import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {BrowserRouter} from 'react-router-dom';
import Spinner from "./components/spinner/Spinner";
import {PersistGate} from 'redux-persist/integration/react'
import {persistor, store} from "./redux/store";
import {Provider} from "react-redux";


const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
	<React.StrictMode>
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<Suspense fallback={<Spinner/>}>
					<BrowserRouter>
						<App/>
					</BrowserRouter>
				</Suspense>
			</PersistGate>
		</Provider>
	</React.StrictMode>
);