let L: any;
if (typeof window !== 'undefined') {
	import('leaflet').then(leaflet => {
		L = leaflet;
	});
}

export interface HoverCityOptions {
	IscitySelected: boolean;
	selectedEventType: string | null;
	eventCounts: Array<{ city: string; t: number | null }>;
	interpretTValue: (t: number) => string;
}

let activeTooltips: any[] = [];

export function addFeatureEvents(layer: any): void {
	let tooltip: any = null; // Tooltip referansı dışarıda tanımlanır

	// Mouseover
	layer.on('mouseover', function (e: any) {
		const properties = e.target.feature.properties;
		const name = properties.name || properties.ILCE_ADI;

		tooltip = L.tooltip({
			permanent: false,
			direction: 'top',
			className: 'custom-leaflet-tooltip',
		})
			.setContent(name)
			.setLatLng(e.latlng)
			.addTo(e.target._map);

		activeTooltips.push(tooltip); // Aktif tooltip listesine eklenir

		// Hover stili uygulanır
		layer.setStyle({
			weight: 3,
			color: '#FFD700', // Altın sarısı sınır rengi
		});

		if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
			layer.bringToFront();
		}
	});

	// Mousemove
	layer.on('mousemove', function (e: any) {
		if (tooltip) {
			tooltip.setLatLng(e.latlng); // Tooltip pozisyonu güncellenir
		}
	});

	// Mouseout
	layer.on('mouseout', function (e: any) {
		if (tooltip) {
			e.target._map.removeLayer(tooltip); // Tooltip kaldırılır
			activeTooltips = activeTooltips.filter(t => t !== tooltip); // Listeden çıkarılır
			tooltip = null; // Tooltip sıfırlanır
		}

		// Varsayılan stil geri uygulanır
		layer.setStyle({
			weight: 1,
			color: '#094151',
		});
	});
}

export function removeAllTooltips(map: any): void {
	activeTooltips.forEach(tooltip => map.removeLayer(tooltip));
	activeTooltips = [];
}

export function getFeatureStyle(eventData: any, selectedEventType: string | null): (feature: any) => any {
	const colorScales: Record<string, string[]> = {
		'cf5f045f-a360-9c6f-5566-757fd856878a': ['#EBF5FF', '#FFDAC6', '#FFC19F', '#FCAE84', '#EC8E5B'], //Terörizm (TE)
		'450b0d5e-6639-3649-b055-45ad25dc1e72': ['#FEE0E0', '#FFB8B8', '#FF9B9B', '#FF7C7C', '#F35E5E'], //Örgütlü Suçlar (OS)
		'ff4ee709-9d4d-3b66-e0c7-09456d55d54d': ['#F1FFF6', '#DEFFE9', '#B5EBC7', '#93DDAC', '#67C086'], //Sosyal ve Politik İstikrarsızlık (SP)
		'0d2afe18-19b2-d63a-5d3e-6f92105b778b': ['#DEF2E6', '#C0E3CD', '#9ED0B1', '#7BB691', '#689D7B'], //Çevresel ve Doğal Afetler (ÇA)
		'8c61ead0-105c-ea03-9984-7d9283eb9c5f': ['#E1F8FF', '#D7F5FF', '#ABE2F4', '#82CAE2', '#63ABC3'], //Stratejik Gelişmeler (SG)
		'0a6d93e8-135c-d061-cac2-e9ff390567c8': ['#DEEFFF', '#C0E1FF', '#96C8F6', '#75B1E9', '#4995DB'], //Bireysel ve Adi Suçlar (BA)
		'default': ['#EBF5FF', '#9CC9E1', '#4C9CC2', '#367793', '#215264']
	};

	return (feature: any): any => {
		const name = feature.properties.name || feature.properties.ILCE_ADI;
		// const eventData = eventCounts?.find(count => count.city === name);

		let style: any = {
			color: '#094151', // Border color
			weight: 1,
			dashArray: '3', // Border style
			fillColor: 'transparent',
			opacity: 1.0
		};

		if (eventData) {
			const colorScale = colorScales[selectedEventType || 'default'];
			let fillColor = colorScale[0]; // Default fill color

			if (selectedEventType !== null) {
				// Event type seçili olduğunda t-value'ya göre renklendir
				const t = eventData.t;
				if (t > 12) {
					fillColor = colorScale[4]; // Very High
				} else if (t >= 8) {
					fillColor = colorScale[3]; // High
				} else if (t >= 3) {
					fillColor = colorScale[2]; // Medium
				} else if (t > 0) {
					fillColor = colorScale[1]; // Low
				}
			} else {
				// Event type seçili değilse count'a göre renklendir
				const eventCount = eventData.count;
				if (eventCount >= 1000) {
					fillColor = colorScale[4];
				} else if (eventCount >= 500) {
					fillColor = colorScale[3];
				} else if (eventCount >= 100) {
					fillColor = colorScale[2];
				} else if (eventCount >= 1) {
					fillColor = colorScale[1];
				}
			}

			style.fillColor = fillColor;
			style.fillOpacity = 0.8;
		}

		return style;
	};
}

