import {IActorCategory, IEventType} from "../reducers/appReducer";

export const saveCities = (cities: string[]) => {
	return {
		type: 'SAVE_CITIES' as const,
		payload: cities
	};
};
export const saveActorCategories = (actorCategories: IActorCategory[]) => {
	return {
		type: 'SAVE_ACTOR_CATEGORIES' as const,
		payload: actorCategories
	};
};
export const saveEventTypes = (eventTypes: IEventType[]) => {
	return {
		type: 'SAVE_EVENT_TYPES' as const,
		payload: eventTypes
	};
};
export const saveDistrictBounds = (districtBounds: any) => {
	return {
		type: 'SAVE_DISTRICT_BOUNDS' as const,
		payload: districtBounds
	};
};
export const resetAppCahce = () => {
	return {
		type: 'RESET_APP_CACHE' as const,
	};
};

export type AppAction = ReturnType<typeof saveCities | typeof saveActorCategories | typeof saveEventTypes | typeof resetAppCahce | typeof saveDistrictBounds>;