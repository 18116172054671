import Grid from "@mui/material/Grid";
import {Autocomplete, Checkbox, Paper} from "@mui/material";
import {subYears} from 'date-fns';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {useEffect, useState} from "react";

import CustomDateRangePicker, {IRange} from "../../../components/dateRangePicker/CustomDateRangePicker";
import {useSelector} from "../../../redux/store";
import {IActorCategory, IEventType} from "../../../redux/reducers/appReducer";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

interface IFilterProps {
	filters: any,
	setFilters: any,
	defaults: any
}

const Filter = (props: IFilterProps) => {
	const {filters, setFilters, defaults} = props
	const [olayYeri, setOlayYeri] = useState<string | null>(null);
	const [olayTuru, setOlayTuru] = useState<IEventType | null>(null);
	const [aktorler, setAktorler] = useState<IActorCategory[]>([]);
	const [dateRange, setDateRange] = useState<IRange>({
		startDate: defaults.dateStart,
		endDate: defaults.dateEnd,
	});
	const {cities, actorCategories, eventTypes} = useSelector(state => state.app)

	useEffect(() => {
		setOlayYeri(filters.city);
	}, [filters]);
	return <Paper>
		<Grid container spacing={2} paddingX={"1rem"}  sx={{backgroundColor: '#fff'}}>
			<Grid item xs={12} md={3} padding={"1rem"}>
				<CustomDateRangePicker dateRange={dateRange} setDateRange={setDateRange} label={"Olay Tarihi"}/>
			</Grid>
			<Grid item xs={12} md={3} padding={"1rem"}>
				<Autocomplete
					fullWidth
					size={"small"}
					options={cities ?? []}
					value={olayYeri}
					onChange={(_, value) => setOlayYeri(value)}
					renderInput={(params) => (
						<TextField
							{...params}
							label="Olay Yeri"
						/>
					)}
				/>
			</Grid>
			<Grid item xs={12} md={3} padding={"1rem"}>
				<Autocomplete
					fullWidth
					size={"small"}
					options={eventTypes ?? []}
					getOptionLabel={option => option.label}
					onChange={(_, value) => setOlayTuru(value)}
					renderInput={(params) => (
						<TextField
							{...params}
							label="Olay Türü"
							value={olayTuru}
							onChange={(event: any) => setOlayTuru(event.target.value)}
						/>
					)}
				/>
			</Grid>
			{/*<Grid item xs={12} md={3} padding={"2rem"}>
				<Autocomplete
					fullWidth
					size={"small"}
					multiple
					options={actorCategories ?? []}
					value={aktorler}
					onChange={(_, value) => setAktorler([...value])}
					renderInput={(params) => (
						<TextField
							{...params}
							label="Aktör"
							onChange={(event: any) => setAktorler(event.target.value)}
						/>
					)}
					renderTags={() => null}
					renderOption={(option, element) => (
						<div>
							<Checkbox checked={aktorler?.includes(element)} onChange={(_, value) => {
								if (!value) setAktorler(aktorler.filter((item: IActorCategory) => item.value !== element.value))
								else setAktorler([...aktorler, element])
							}}/>
							{element.label}
						</div>
					)}
				/>
			</Grid>*/}
			<Grid item xs={12} md={3}>
				<Box display={"inline-flex"} width={"100%"} gap={"1rem"}>
					{/*<Box flexGrow={1}/>*/}
					<Button style={{backgroundColor: '#094151', color: '#fff', width: '50%'}}
					        onClick={() => {
						        setFilters({
							        ...filters,
							        dateStart: dateRange.startDate,
							        dateEnd: dateRange.endDate,
							        city: olayYeri,
							        eventTypeId: olayTuru?.value,
							        actorId: aktorler.map((item: IActorCategory) => item.value),
						        })
					        }}>
						Filtrele
					</Button>
					<Button style={{backgroundColor: '#094151', color: '#fff', width: '50%'}}
					        onClick={() => {
						        setFilters(defaults);
						        setDateRange({startDate: subYears(new Date(), 1), endDate: new Date()});
						        setOlayYeri("");
						        setOlayTuru(null);
						        setAktorler([]);
					        }}
					>
						Temizle
					</Button>
				</Box>
			</Grid>
		</Grid>
	</Paper>
}
export default Filter;