import {combineReducers} from 'redux';
import authReducer from './authReducer';
import appReducer from "./appReducer";
import loadingReducer from "./loadingReducer";

import storage from 'redux-persist/lib/storage';
import {persistReducer} from 'redux-persist';

const persistConfig = {
	key: 'root',
	storage,
};
const persistAuthReducer = persistReducer(persistConfig, authReducer);
const persistAppReducer = persistReducer(persistConfig, appReducer);


const rootReducer = combineReducers({
	auth: persistAuthReducer,
	app: persistAppReducer,
	loading: loadingReducer
})

export default rootReducer;
