import React from 'react';
import ApiBase from "./api/apiBase";
import {CssBaseline, ThemeProvider} from "@mui/material";
import {useRoutes} from "react-router-dom";
import Routes from "./routes/Routes";
import {useSelector} from "./redux/store";
import {ThemeSettings} from "./theme/Theme";
import Spinner from "./components/spinner/Spinner";
import ScrollToTop from "./components/scrollToTop/ScrollToTop";
import 'leaflet/dist/leaflet.css';

function App() {
	const routing = useRoutes(Routes());
	const accessToken = useSelector(state => state.auth.accessToken);
	ApiBase.putAccessTokenInfo(accessToken)
	const theme = ThemeSettings();
	return (
		<ThemeProvider theme={theme}>
			<CssBaseline/>
			<Spinner/>
			<ScrollToTop>{routing}</ScrollToTop>
		</ThemeProvider>
	);
}

export default App;
