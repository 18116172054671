import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import Typography from '@mui/material/Typography';

interface CustomChordGroup extends d3.ChordGroup {
    angle?: number;
}

type RibbonType = d3.Ribbon | d3.Chord;

export const ChordDiagram = () => {
    const svgRef = useRef<SVGSVGElement | null>(null);

    useEffect(() => {
        if (!svgRef.current) return;

        const data = [
            { source: "İstanbul", target: "Ankara", value: 100 },
            { source: "İstanbul", target: "İzmir", value: 80 },
            { source: "Ankara", target: "İzmir", value: 50 },
            { source: "İzmir", target: "İstanbul", value: 60 },
            { source: "Ankara", target: "İstanbul", value: 70 }
        ];

        d3.select(svgRef.current).selectAll("*").remove();

        const width = 500;
        const height = width;
        const innerRadius = Math.min(width, height) * 0.5 - 90;
        const outerRadius = innerRadius + 10;

        const names = d3.sort(d3.union(data.map(d => d.source), data.map(d => d.target)));
        const index = new Map(names.map((name, i) => [name, i]));

        const matrix = Array.from(index, () => new Array(names.length).fill(0));
        for (const { source, target, value } of data) {
            const sourceIndex = index.get(source);
            const targetIndex = index.get(target);

            if (sourceIndex !== undefined && targetIndex !== undefined) {
                matrix[sourceIndex][targetIndex] += value;
            }
        }

        const chord = d3.chordDirected()
            .padAngle(10 / innerRadius)
            .sortSubgroups(d3.descending)
            .sortChords(d3.descending);

        const arc = d3.arc()
            .innerRadius(innerRadius)
            .outerRadius(outerRadius);

        const ribbon = d3.ribbonArrow()
            .radius(innerRadius - 1)
            .padAngle(1 / innerRadius);

        const colors = d3.quantize(d3.interpolateRainbow, names.length);

        const svg = d3.select(svgRef.current)
            .attr("viewBox", [-width / 2, -height / 2.4, width, height])
            .style("width", "100%")
            .style("height", "100%")
            .style("font", "14px sans-serif");

        const chords = chord(matrix);

        const group = svg.append("g")
            .selectAll("g")
            .data(chords.groups)
            .join("g");

        group.append("path")
            .attr("fill", d => colors[d.index])
            .attr("d", arc as any);

        group.append("text")
            .each(function(d) { (d as CustomChordGroup).angle = (d.startAngle + d.endAngle) / 2; })
            .attr("dy", "0.35em")
            .attr("transform", (d: CustomChordGroup) => `
        rotate(${((d.angle || 0) * 180 / Math.PI - 90)})
        translate(${outerRadius + 5})
        ${(d.angle || 0) > Math.PI ? "rotate(180)" : ""}
      `)
            .attr("text-anchor", (d: CustomChordGroup) => (d.angle || 0) > Math.PI ? "end" : null)
            .text(d => names[d.index]);

        svg.append("g")
            .attr("fill-opacity", 0.75)
            .selectAll("path")
            .data(chords)
            .join("path")
            .style("mix-blend-mode", "multiply")
            .attr("fill", d => colors[d.target.index])
            .attr("d", ribbon as any)
            .append("title")
            .text(d => `${names[d.source.index]} → ${names[d.target.index]} ${d.source.value}`);

    }, []);

    return (
        <>
            <Typography variant="h5" gutterBottom sx={{ paddingTop: '1rem', display: 'flex',
                flexDirection: 'column', alignItems: 'center' }}>
                İller Arası İlişki Ağı
            </Typography>
            <div style={{ height: '92%', display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                <svg
                    ref={svgRef}
                    style={{ maxHeight: '100%' }}
                />
            </div>
        </>
    );
};

export default ChordDiagram;