import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import Filter from "./components/Filter";
import {useEffect, useState} from "react";
import {subYears} from "date-fns";
import eventsApi from "../../api/eventsApi/eventsApi";
import Map from "./components/Map";
import Summary from "./components/Summary";

const defaultFiltreler = {
	dateStart: subYears(new Date(), 1),
	dateEnd: new Date(),
}

const Home = () => {
	const [filters, setFilters] = useState<any>(defaultFiltreler)
	const [events, setEvents] = useState<any>([])
	const [pins, setPins] = useState<any>([])

	useEffect(() => {
		if (filters?.dateStart && filters?.dateEnd) {
			eventsApi.getEvents(filters).then(res => setEvents(res))
		}
		eventsApi.getPins().then((res: any) => setPins(res?.items ?? []))
	}, [filters])

	return <Box width={"100%"}>
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Filter filters={filters} setFilters={setFilters} defaults={defaultFiltreler}/>
			</Grid>
			<Grid item xs={12} md={9}>
				<Map
					setPins={setPins}
					pins={pins}
					events={events}
					filters={filters}
					setFilters={setFilters}
				/>
			</Grid>
			<Grid item xs={12} md={3}>
				<Summary
					eventTypeCounts={events.eventTypeCounts}
					districtEventCounts={events.districtEventCounts}
					startDate={filters.dateStart}
					endDate={filters.dateEnd}
				/>
			</Grid>
		</Grid>
	</Box>
}
export default Home;